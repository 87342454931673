<smvd-ui-card [border]="false" [hasPadding]="false" class="__service-card">
    <div class="u-flex-responsive">
        <div class="__image-holder">
            <div
                class="u-flex-row u-flex-align-items-center u-flex-justify-content-center u-h100p __background-{{ service }}">
                <ng-content select="[image-content]"></ng-content>
            </div>
        </div>
        <div class="__content-holder u-flex-column u-flex-justify-content-space-between u-w100p">
            <div class="u-margin-bottom-double-up-to-and-including-phone-landscape">
                <div class="u-flex-row u-flex-align-items-center u-margin-bottom-half">
                    <h4 class="u-flex-grow-1 u-font-size-18 u-line-height-20p">{{ title }}</h4>
                    <div class="__header-right u-margin-left u-flex-shrink-0">
                        <ng-content select="[header-right]"></ng-content>
                    </div>
                </div>
                <p class="u-color-muted">{{ description }}</p>
            </div>
            <div class="u-flex-responsive">
                <ng-content select="[actions-content]"></ng-content>
            </div>
        </div>
    </div>
</smvd-ui-card>
